<template>
  <div class="eventDetailPageWrap">
    <m-layout-bar>
      <!-- :style="{background:'url('+bgImgUrl+')',backgroundSize:'100% 100%'}" -->
      <div
        class="eventDetailWrap"
        :style="{background:'url('+bgImgUrl+')',backgroundSize:'100% 100%'}"
      >
        <!-- <div class="bg">
          
          <img :src="require('../assets/image/company/bg.png')" alt />
        </div>-->
        <div class="titleWrap">
          <img :src="require('../assets/image/eventInfo/titleLog.png')" alt />
          <img
            class="btn_back"
            @click="goToList"
            :src="require('../assets/image/eventInfo/btn.png')"
            alt
          />
        </div>
        <div class="content">
          <span class="title">{{dataInfo.class_type}}</span>
          <img class="gap" :src="require('../assets/image/eventInfo/gap.jpg')" alt />
          <div class="contentWrap">
            <span class="title">{{dataInfo.title}}</span>
            <div class="subtitleWrap">
              <span class="from subtitle">新闻来源：铁定溜溜</span>
              <span class="date subtitle">发布时间：{{createdTime}}</span>
            </div>
          </div>

          <!-- <div class="imgWrap">
              <img :src="require('../assets/image/eventInfo/pic.jpg')" alt />
              <img :src="baseUrl + dataInfo.photo" alt />
          </div>-->
          <!-- <p>{{dataInfo.content_info}}</p> -->
          <div class="desc" v-html="dataInfo.content_info"></div>
          <div class="btnsWrap">
            <div class="btnWrap prev">
              <img
                class="first back"
                @click="toPrevPage(dataInfo.last_id)"
                :src="require('../assets/image/eventInfo/btn_left.png')"
                alt
              />
              <span v-if="dataInfo.last_id!=null">{{dataInfo.last_title}}</span>
              <span v-else class="none">已经没有了~</span>
            </div>
            <div class="btnWrap last">
              <span v-if="dataInfo.previous_id!=null" class="first">{{dataInfo.previous_title}}</span>
              <span v-else class="first none">已经没有了~</span>
              <img
                @click="goNext(dataInfo.previous_id)"
                :src="require('../assets/image/eventInfo/btn_right.png')"
                alt
              />
            </div>
          </div>
        </div>
      </div>
    </m-layout-bar>
  </div>
</template>

<script type="text/ecmascript-6">
import LayoutBar from "@/components/LayoutBar";
import axios from "axios";
import config from "@/utils/config";

export default {
  components: {
    [LayoutBar.name]: LayoutBar
  },
  data() {
    return {
      bgImgUrl: require("../assets/image/company/bg.png"),
      id: "",
      dataInfo: {}
    };
  },
  create() {},
  mounted() {
    this.getData(config.eventDetailUrl, { id: this.id });
  },
  computed: {
    baseUrl() {
      return config.webBaseUrl;
    },
    createdTime() {
      let time = this.dataInfo.create_time + "";
      return time.substring(0, 10);
    }
  },
  watch: {
    // 靠这个方式实现路由改变后，数据不显示的问题。用watch来触发数据加载和数据绑定。
    $route(newVal, oldVal) {
      // console.log("this.id:", this.id);
      // console.log("监视变化3：", newVal);
      this.getData(config.eventDetailUrl, { id: this.id });
    }
  },
  methods: {
    async getData(url) {
      try {
        this.id = this.$route.query.id;
        let res = await axios.get(url, {
          params: {
            id: this.id
          }
        });
        if (res.data.code == "0" && res.data.data instanceof Array) {
          if (res.data.data.length > 0) {
            let tempData = JSON.parse(JSON.stringify(res.data.data));
            if (tempData.length > 0) {
              this.dataInfo = tempData[0];
            }

            // console.log("res.data.data:", this.dataInfo);
          }
        }
      } catch (e) {
        console.log("res err:", e);
      }
    },
    toPrevPage(id) {
      if (!id) return;
      // console.log("id", id);
      this.id = id;
      this.$router.push({ path: "/eventInfoDetail", query: { id: this.id } });
    },
    goToList() {
      // this.$router.go(-1);
      // /eventInfo/announcement
      this.$router.push({
        path: "/eventInfo/announcement"
      });
    },
    goBack() {},
    goNext(id) {
      if (!id) return;
      this.id = id;
      this.$router.push({ path: "/eventInfoDetail", query: { id: this.id } });
    }
  }
};
</script>

<style scoped lang="scss" rel="stylesheet/scss">
@import "src/assets/styles/variables.scss";
.eventDetailWrap {
  border: 1px solid transparent;
  .titleWrap {
    position: relative;
    width: 1400px;
    margin: 33px auto 0 auto;
    > .title {
      font-size: 37px;
      font-weight: bold;
      margin-right: 30px;
    }
    .titleLog {
      width: 89px;
      height: 91px;
      vertical-align: bottom;
    }
    .subtitle {
      display: block;
      margin-top: 20px;
    }
    .btn_back {
      position: absolute;
      right: 0;
      bottom: 0px;
    }
  }
  .content {
    width: 1400px;

    margin: 53px auto 50px auto;
    border: 1px solid transparent;

    padding: 25px 47px 27px 47px;
    box-sizing: border-box;
    vertical-align: top;
    background: $main-white;

    > .title {
      font-size: 26px;
      font-weight: bold;
      color: $main-black;
    }

    > .gap {
      display: block;
      margin-top: 14px;
    }

    > .contentWrap {
      margin-top: 45px;

      > .title {
        display: block;
        // width: 537px;
        height: 65px;
        font-size: 27px;
        font-weight: bold;
        overflow: hidden;
      }

      > .subtitleWrap {
        display: block;
        margin-top: 38px;
        // width: 537px;
        height: 45px;
        font-size: 16px;
        color: $font-grayDark;
        overflow: hidden;
        border-bottom: 1px solid $main-black;
        > .subtitle {
          &.from {
            margin-right: 100px;
          }
        }
      }
    }
    > .desc {
      border-bottom: 1px solid $main-black;
      > p {
        font-size: 14px;
        line-height: 1.8;
        margin-top: 33px;
      }
      .imgWrap {
        width: 100%;
        min-height: 1000px;
        background: $main-gray;
        > img {
          width: 100%;
          height: 100%;
          margin-top: 39px;
          margin-bottom: 50px;
        }
      }
    }

    > .btnsWrap {
      // width: 1340px;
      margin: 30px;
      display: flex;
      > .btnWrap {
        flex: none;
        width: 49%;
        display: flex;
        align-items: center;
        &:nth-of-type(1) {
          border-right: 1px solid $main-black;
        }
        > img {
          width: 54px;
          height: 54px;
          vertical-align: middle;
        }
        > span {
          flex: 1 1 auto;
          // width: calc(100% - 84px);
          height: 54px;
          line-height: 54px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          &.none {
            color: $font-grayDark;
          }
        }
      }
      > .btnWrap.prev {
        padding-right: 10px;
        > img {
          margin-right: 30px;
        }
      }
      > .btnWrap.last {
        padding-left: 10px;
        > span {
          text-align: right;
        }
        > img {
          margin-left: 30px;
        }
      }
      // > .btnWrap {
      //   display: inline-block;
      //   width: 640px;
      //   height: 97px;
      //   // line-height: 97px;
      //   box-sizing: border-box;

      //   > .first {
      //     margin-right: 30px;

      //     // height:
      //     &.none {
      //       color: $font-grayDark;
      //     }
      //   }
      //   > img {
      //     width: 54px;
      //     width: 54px;
      //     vertical-align: middle;
      //   }
      //   > span {
      //     width: calc(100% - 158px);
      //     height: 54px;
      //     overflow: hidden;
      //   }
      // }
      // > .btnWrap.prev {
      //   border-right: 1px solid $main-black;
      //   padding-left: 74px;
      //   text-align: left;
      // }
      // > .btnWrap.last {
      //   padding-right: 74px;
      //   text-align: right;
      // }
    }
  }
}
</style>
